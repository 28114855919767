import React from 'react';
import PropTypes from 'prop-types';
import { storyblokEditable } from 'gatsby-source-storyblok';

import ButtonsProductCartOptions from '../ButtonsProductCartOptions';
import { findSampleVariant } from '../../lib/product';
import useMobile from '../useMobile';

const DynamicProductCartButtons = ({ blok }) => {
  const isMobile = useMobile();
  return (
    <ButtonsProductCartOptions
      {...storyblokEditable(blok)}
      {...blok}
      product={blok?.product?.item}
      variant={findSampleVariant(blok?.product?.item?.variants || [])}
      useIcon={true}
      gap={isMobile ? 'medium' : 'none'}
      defaultBorders={isMobile}
      buttonFlex={false}
      border={
        isMobile
          ? []
          : [
              { side: 'vertical', size: 'small' },
              { side: 'bottom', size: 'small' },
            ]
      }
    />
  );
};

DynamicProductCartButtons.propTypes = {
  blok: PropTypes.shape({
    product: PropTypes.object.isRequired,
  }),
};

export default DynamicProductCartButtons;
